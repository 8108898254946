import "reflect-metadata"; // Always on top
import { createApp } from "vue";
import * as banno from "@/branchmanager/banno.import";
import { createPinia } from "pinia";
import piniaPluginPersistedstate from "pinia-plugin-persistedstate";
import App from "@/branchmanager/App.vue";
import iDScanDVSDialog from "@/common/components/iDScanDVSDialog/iDScanDVSDialog.vue";
import bltIDUploaderDialog from "@/common/components/bltIdUploaderDialog/bltIdUploaderDialog.vue";
import router from "@/branchmanager/router";
import container from "@/common/dependency.configs";
import DEPENDENCYTYPES from "@/common/dependency.types";
import { useAllDirectives } from "@/common/directives/appch.directives.use";
import { useAllComponents } from "@/common/components/appch.components.use";
import APP_CODES from "@/common/enums/appCodeEnum";
import type IRouterService from "@/common/services/utils/IRouterService";
import RouterService from "@/branchmanager/router/RouterService";
import { EmpPlaidStore } from "@/branchmanager/stores/EmpPlaidStore";
import type { IPlaidStore } from "@/common/services/Vendor/Plaid/IPlaidStore";
import { $injStr } from "@/common/decorators/depinject";
import { VueMaskDirective } from "v-mask";
// Importing below components here to avoid unit tests failure for IDVC library.
import { EmpWorkspaceMetadataStore } from "@/branchmanager/stores/EmpWorkspaceMetadataStore";
import type IHttpErrorHandler from "@/common/services/connect/IHttpErrorHandler";
import EmpHttpErrorHandler from "@/branchmanager/services/EmpHttpErrorHandler";
import { EmpWorkspaceStore } from "@/branchmanager/stores/EmpWorkspaceStore";
import { bltTile, bltSearch, bltSearchItem, bltSearchDetail } from "@/common/components/appch.components.module";
import { reuseAllOpenComponents } from "@/branchmanager/app/workspace/open.components.reuse";
import BltToolbarFactory from "@/branchmanager/services/BltToolbarFactory";
import { useAllEmpChComponents } from "@/branchmanager/app/components/emp.components.use";
import type IUiFieldsFactory from "@/common/services/Fields/IUiFieldsFactory";
import EmpUiFielsFactory from "@/branchmanager/services/EmpUiFieldsFactory";
import WorkspaceTabEnrollmentFactory from "@/branchmanager/services/WorkspaceTabEnrollmentFactory";
import type IWorkspaceTabEntityFactory from "@/branchmanager/services/IWorkspaceTabEntityFactory";
import WorkspaceTabApplicantFactory from "@/branchmanager/services/WorkspaceTabApplicantFactory";
import type IWorkspaceEntityInfoFactory from "@/branchmanager/services/IWorkspaceEntityInfoFactory";
import WorkspacePersonInfoFactory from "@/branchmanager/services/WorkspacePersonInfoFactory";
import WorkspaceBusinessInfoFactory from "@/branchmanager/services/WorkspaceBusinessInfoFactory";
import EmpApplicantValidationFactory from "@/branchmanager/services/EmpApplicantValidationFactory";
import type IApplicantValidationFactory from "@/common/services/Applicant/IApplicantValidationFactory";

import type IWorkflowStore from "@/branchmanager/stores/IWorkflowStore";
import { EmpWorkflowStore } from "@/branchmanager/stores/EmpWorkflowStore";
import type ICardContentHeightUtil from "@/common/services/utils/ICardContentHeightUtil";
import EmpCardContentHeightUtil from "@/branchmanager/services/utils/EmpCardContentHeightUtils";
import { BranchLanguageKeysConstant as OpenLanguageKeysConstant } from "@/branchmanager/app/constant/BranchLanguageKeysConstant";
import type IEmpWorkspaceStore from "@/branchmanager/stores/IEmpWorkspaceStore";
import type IEmpWorkspaceMetadataStore from "@/branchmanager/stores/IEmpWorkspaceMetadataStore";
import EmpNotificationFactory from "@/branchmanager/services/EmpNotificationFactory";
import type INotificationFactory from "@/common/services/INotificationFactory";
import type IColourSchemeUtils from "@/common/services/IColorSchemeUtil";
import ColorSchemeUtil from "@/branchmanager/services/utils/ColorSchemeUtil";
import EmpEnrollmentApplicantCipFactory from "@/branchmanager/services/EmpEnrollmentApplicantCipFactory";
import type IEnrollmentApplicantCipFactory from "@/branchmanager/services/IEnrollmentApplicantCipFactory";
import EmployeeWorkflowFactory from "@/branchmanager/services/EmployeeWorkflowFactory";
import type HttpHandlerConfig from "@/common/interfaces/httpHandler/HttpHandlerConfig";
import EmployeeChannelHttpHandlerConfig from "@/branchmanager/config/EmployeeChannelHttpHandlerConfig";
import onInputDebounced from '@/common/directives/onInputDebounced/onInputDebounced'

const app = createApp(App);

app.config.globalProperties.window = <any>window;
(<any>window).APP_CODE = APP_CODES.OPEN;
app.config.globalProperties.window.$inj = $injStr;
app.config.globalProperties.$institutionConfig = window.institutionConfig;
app.config.globalProperties.window.OpenLanguageKeysConstant = OpenLanguageKeysConstant;

/* To stay on the same Url when browser back button click*/
window.onpopstate = function () {
  history.go(0);
};

const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);
app.use(pinia);
app
  .directive("mask", VueMaskDirective)
  .directive("on-input-debounced", onInputDebounced);

useAllDirectives(app);
useAllComponents(app);
useAllEmpChComponents(app);

app.component("blt-tile", bltTile);
app.component("blt-search", bltSearch);
app.component("blt-search-item", bltSearchItem);
app.component("blt-search-detail", bltSearchDetail);

reuseAllOpenComponents(app);

container
  .bind<INotificationFactory>(DEPENDENCYTYPES.INotificationFactory)
  .to(EmpNotificationFactory)
  .inSingletonScope();
container
  .bind<IApplicantValidationFactory>(DEPENDENCYTYPES.IApplicantValidationFactory)
  .to(EmpApplicantValidationFactory)
  .inSingletonScope();
container
  .bind<IEnrollmentApplicantCipFactory>(DEPENDENCYTYPES.IEnrollmentApplicantCipFactory)
  .to(EmpEnrollmentApplicantCipFactory)
  .inSingletonScope();
container.bind<HttpHandlerConfig>(DEPENDENCYTYPES.HttpHandlerConfig).to(EmployeeChannelHttpHandlerConfig).inSingletonScope()
container.bind<IHttpErrorHandler>(DEPENDENCYTYPES.IHttpErrorHandler).to(EmpHttpErrorHandler).inSingletonScope();
container
  .bind<IEmpWorkspaceMetadataStore>(DEPENDENCYTYPES.IWorkspaceMetadataStore)
  .toConstantValue(EmpWorkspaceMetadataStore());
container.bind<IWorkflowStore>(DEPENDENCYTYPES.IWorkflowStore).toConstantValue(EmpWorkflowStore());
container.bind<IEmpWorkspaceStore>(DEPENDENCYTYPES.IWorkspaceStore).toConstantValue(EmpWorkspaceStore());
container.bind<IPlaidStore>(DEPENDENCYTYPES.IPlaidStore).toConstantValue(EmpPlaidStore());
container.bind<IRouterService>(DEPENDENCYTYPES.IRouterService).to(RouterService).inSingletonScope();
container.bind<BltToolbarFactory>(BltToolbarFactory).to(BltToolbarFactory).inSingletonScope();
container.bind<EmployeeWorkflowFactory>(EmployeeWorkflowFactory).to(EmployeeWorkflowFactory).inSingletonScope();
container.bind<IColourSchemeUtils>(DEPENDENCYTYPES.IColourSchemeUtils).to(ColorSchemeUtil).inSingletonScope();
container.bind<IUiFieldsFactory>(DEPENDENCYTYPES.IUiFieldsFactory).to(EmpUiFielsFactory).inSingletonScope();
container
  .bind<IWorkspaceTabEntityFactory>(DEPENDENCYTYPES.EnrollmentTabs)
  .to(WorkspaceTabEnrollmentFactory)
  .inSingletonScope();
container
  .bind<IWorkspaceTabEntityFactory>(DEPENDENCYTYPES.ApplicantTabs)
  .to(WorkspaceTabApplicantFactory)
  .inSingletonScope();
container.bind<IWorkspaceEntityInfoFactory>(DEPENDENCYTYPES.PERSON).to(WorkspacePersonInfoFactory).inSingletonScope();
container
  .bind<IWorkspaceEntityInfoFactory>(DEPENDENCYTYPES.BUSINESS)
  .to(WorkspaceBusinessInfoFactory)
  .inSingletonScope();
container
  .bind<ICardContentHeightUtil>(DEPENDENCYTYPES.ICardContentHeightUtil)
  .to(EmpCardContentHeightUtil)
  .inSingletonScope();

app.use(router);
app.mount("#app");

//ensure the file is not dropped during treeshaking by consuming
banno.HelloBanno();
